// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pagess-contact-js": () => import("./../../../src/pagess/contact.js" /* webpackChunkName: "component---src-pagess-contact-js" */),
  "component---src-pagess-index-js": () => import("./../../../src/pagess/index.js" /* webpackChunkName: "component---src-pagess-index-js" */),
  "component---src-pagess-photos-js": () => import("./../../../src/pagess/photos.js" /* webpackChunkName: "component---src-pagess-photos-js" */),
  "component---src-pagess-piscine-js": () => import("./../../../src/pagess/piscine.js" /* webpackChunkName: "component---src-pagess-piscine-js" */),
  "component---src-pagess-situation-js": () => import("./../../../src/pagess/situation.js" /* webpackChunkName: "component---src-pagess-situation-js" */),
  "component---src-pagess-tarifs-js": () => import("./../../../src/pagess/tarifs.js" /* webpackChunkName: "component---src-pagess-tarifs-js" */),
  "component---src-pagess-villas-js": () => import("./../../../src/pagess/villas.js" /* webpackChunkName: "component---src-pagess-villas-js" */)
}

